<template>
    <div class="card st-framework-card">

        <div class="card-header">
            <table style="width: 100%;">
                <tr>
                    <td style="padding: 0 0 0 1rem; width: 280px;">
                        <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                        {{ $i18n.tc('translations.' + name, 2).toUpperCase() }}
                    </td>
                    <template v-if="serverDataLoaded">
                        <td style="width: 1rem;"></td>
                        <template v-if="data.model.project">
                            <td style="padding: 0 10px; border: solid 1px lightgray; width: 30%;" v-if="data.model.phase">
                                {{ data.model.phase.label }}
                            </td>
                            <td style="width: 30%;" v-else/>
                            <td style="width: 1rem;"/>
                            <td style="padding: 0 10px; border: solid 1px lightgray;">
                                <LinkAction :action="actions.modelLinkAction"/>
                            </td>
                            <td style="padding: 0; width: 90px; text-align: right;">
                                (#{{ data.model.phase ? data.model.phase.id : (data.model.order ? data.model.order.id : data.model.project.id) }})
                            </td>
                        </template>
                        <template v-else>
                            <td style="padding: 0 10px; border: solid 1px lightgray;">
                                <router-link :to="{name: 'order_list'}" class="st-cardheader-link">
                                    {{ $i18n.t('translations.SketchUp model is not linked to a project') }}!
                                </router-link>
                            </td>
                        </template>
                        <td style="width: 1rem;"></td>
                    </template>
                </tr>
            </table>
        </div>

        <div class="card-body p-0" v-if="serverDataLoaded">
            <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                   with-filter with-pagination pagination-always-visible no-state-loading autofocus :lines-per-page=data.crudLinesPerPage
                   :rows="processedComponentList"
                   :fields="fields.table" :actions="actions.table" :state="state"
                   v-on:clear-selection="clearSelection"
                   v-on:delete-selection="deleteSelection"
                   v-on:download-event="download"
                   v-on:get-components="getComponents"
                   v-on:select-all="selectAll"
                   v-on:select-component="selectComponent"/>
        </div>

    </div>
</template>

<script>
import FileDownload from "@/mixins/FileDownload";
import LinkAction from "@/components/actions/LinkAction.vue";
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'CuttingList',
    components: {
        LinkAction,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    mixins: [FileDownload],
    data() {
        return {
            serverDataLoaded: false,
            tableReload: 0,
            sketchUpToolData: null,
            data: null,
            fields: null,
            actions: null,
            hasTags: false,
            componentTag: '',
            clearComponents: true,
            processedComponentList: [],
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        deleteSelection() {
            let i = 0;
            while ((i = this.processedComponentList.findIndex(item => item.selected)) !== -1) {
                this.processedComponentList.splice(i, 1);
            }
            this.showOrHideLabelField();
            this.tableReload++;
        },
        download(choice) {
            let noSelection = true;
            this.processedComponentList.forEach(component => {
                if (component.selected) {
                    noSelection = false;
                }
            });
            let components = [];
            this.processedComponentList.forEach(component => {
                if (noSelection || component.selected) {
                    components.push({
                        ...(this.hasTags && {'tag': component.tag}),
                        'definition': component.definition,
                        'product_name': component.product_name,
                        'attribute_eenheid': component.attribute_eenheid,
                        'attribute_hoeveelheid': component.attribute_hoeveelheid,
                        'quantity': component.quantity,
                    });
                }
            });
            this.state.loading = true;
            this.$http.post(choice.api, {
                'components': components,
            }, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("CuttingList:download():error:", error);
            });
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage_cutting_list/' + this.sketchUpToolData.project_id + '/' + this.sketchUpToolData.order_id + '/' + this.sketchUpToolData.phase_id, {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.serverDataLoaded = true;
                sketchup.getComponentInstances();
            }).catch((error) => {
                console.log("CuttingList:fetchData():error:", error);
            });
        },
        getComponentInstancesCallback(componentInstances) {
            if (this.clearComponents) {
                this.processedComponentList = [];
            }

            this.$worker.run((rawComponentList, processedComponentList, products, componentTag, assemblyDefinitions) => {
                let newProcessedComponentList = processedComponentList;

                rawComponentList.forEach(component => {
                    component.attribute_hoeveelheid = Math.round((parseFloat(component.attribute_hoeveelheid) + Number.EPSILON) * 1000) / 1000;
                    if (component.attribute_naam) {
                        if (component.attribute_zzzaaglijst === 'True' && component.attribute_eenheid === 'lm') {
                            component.attribute_eenheid = 'mm';
                            component.attribute_hoeveelheid = component.attribute_hoeveelheid * 1000;
                        }
                        var product = products.find(p => {
                            return p.reference === component.attribute_naam;
                        });
                        if (product !== undefined ||
                            component.attribute_zzzaaglijst === 'True') {
                            let definition = component.definitions.find(function (definition) {
                                return assemblyDefinitions.includes(definition.substring(0, definition.indexOf("#")));
                            });
                            definition = definition ? definition : '';
                            let i = newProcessedComponentList.findIndex(item =>
                                (item.tag === componentTag) &&
                                (item.definition === definition) &&
                                (item.attribute_naam === component.attribute_naam) &&
                                (parseInt(item.attribute_hoeveelheid * 1000) === parseInt(component.attribute_hoeveelheid * 1000))
                            );
                            if (i === -1) {
                                newProcessedComponentList.push({
                                    'entity_ids': [component.entity_id],
                                    'selected': false,
                                    'tag': componentTag,
                                    'definition': definition,
                                    'product_name': product?.product_name ?? component.attribute_naam,
                                    'attribute_naam': component.attribute_naam,
                                    'attribute_eenheid': component.attribute_eenheid,
                                    'attribute_hoeveelheid': component.attribute_hoeveelheid,
                                    'quantity': 1,
                                });
                            } else {
                                if (newProcessedComponentList[i].entity_ids.indexOf(component.entity_id) === -1) {
                                    newProcessedComponentList[i].entity_ids.push(component.entity_id);
                                }
                                newProcessedComponentList[i].quantity++;
                            }
                        }
                    }
                });

                return newProcessedComponentList;
            }, [JSON.parse(componentInstances), this.processedComponentList, this.data.products, this.componentTag, this.data.settings.assembly_definitions]).then(data => {
                this.processedComponentList = data;
                let count = this.processedComponentList.length;
                for (let i = 0; i < count; i++) {
                    this.processedComponentList[i].id = i + 1;
                }

                this.processedComponentList.sort(function (a, b) {
                    if (a.tag < b.tag) return -1;
                    if (a.tag > b.tag) return 1;
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.attribute_naam < b.attribute_naam) return -1;
                    if (a.attribute_naam > b.attribute_naam) return 1;
                    if (a.attribute_hoeveelheid < b.attribute_hoeveelheid) return -1;
                    if (a.attribute_hoeveelheid > b.attribute_hoeveelheid) return 1;
                    return 0;
                });

                this.showOrHideLabelField();
                this.tableReload++;
                this.state.loading = false;
            }).catch(error => {
                console.log("CuttingList:getComponentInstancesCallback():error:", error);
            });
        },
        getComponents(data) {
            this.state.loading = true;
            this.clearComponents = 'clear-components' in data && data['clear-components'] === "1";
            if ('component-tag' in data) {
                this.componentTag = data['component-tag'];
            } else {
                this.componentTag = '';
            }
            sketchup.getComponentInstances();
        },
        getToolDataCallback(data) {
            this.sketchUpToolData = data;
            this.fetchData();
        },
        selectAll(data) {
            let ids = [];
            this.processedComponentList.forEach(component => {
                if (data.ids.includes(component.id)) {
                    ids = ids.concat(component.entity_ids);
                }
            });
            sketchup.selectComponents(ids);
        },
        selectComponent(data) {
            sketchup.selectComponents(data.entity_ids);
        },
        showOrHideLabelField() {
            this.hasTags = false;
            this.processedComponentList.forEach(component => {
                if (component.tag) {
                    this.hasTags = true;
                }
            });
            this.fields.table.forEach(field => {
                if (field.name === "tag") {
                    field.showintable = this.hasTags;
                }
            });
        },
    }
}
</script>

<style scoped>
</style>
